/**
 * Created by linli on 03/11/16.
 */

import {redirectToAuth0Form} from '@/react-app/common/utils/handleAuth0redirect';

(function() {
  'use strict';

  angular
    .module('eva2-angular')
    .controller('InviteUserTokenVerifyCtrl', InviteUserTokenVerifyCtrl);

  function InviteUserTokenVerifyCtrl($state, $stateParams, Invite, CurrentUser, goToDefault, Notifier, ApiService, tFilter) {
    'ngInject';
    let vm = this;
    const textFailed = tFilter('INVITE_TEAM_TOKEN.ERROR.INVALID_LINK');
    const textLoading = tFilter('INVITE_TEAM_TOKEN.LOADING_TEXT');
    const succeeded = tFilter('INVITE_TEAM_TOKEN.SUCCESS_TEXT');
    const notTheInviteAccountErr = tFilter('INVITE_TEAM_TOKEN.ERROR.ACCOUNT_NOT_MATCH');
    
    vm.verifyToken = async () => {
      try {
        const currentUser =await ApiService.getUser();
        CurrentUser.logIn(currentUser);
      } catch (error) {
        // console.log('getting user for verifyToken failed', error);
      }
      Invite.verifyToken({token: vm.token})
        .then((data) => {
          const disallowHistoryBack = {location: 'replace'};
          if (!CurrentUser.loggedIn) {
            if (data.hasAccount) {
              //if the user already have a account, go to the login page with predefined email              
              redirectToAuth0Form($state,'login',data.toEmail);
            } else {
              //if the user does not exist, go to the sign up page with predefined email
              redirectToAuth0Form($state,'signup',data.toEmail);
            }
          } else if (!!CurrentUser && CurrentUser.email === data.toEmail) {
            //if the user has already login as the invite user, join the team and go to the teams page
            Invite.joinInvite({token: vm.token})
              .then(()=> {
                vm.text = succeeded;
                ApiService.getUser()
                  .then((data)=> {
                    CurrentUser.logIn(data);
                  })
                  .finally(()=> {
                    $state.go('app.assignment.overview.statistics', {
                      domain: data.domain,
                      inviteParams: {inviteTeamName: data.siteName}
                    }, disallowHistoryBack);
                  });
              })
              .catch(() => {
                vm.text = textFailed;
              });
          } else {
            Notifier.display(notTheInviteAccountErr, {type: 'failure', ttl: 10000});
            goToDefault();
          }
        })
        .catch(() => {
          vm.text = textFailed;
        });
    };

    vm.token = $stateParams.token;
    if (!vm.token) {
      vm.text = textFailed;
      return;
    } else {
      vm.text = textLoading;
      vm.verifyToken();
    }
  }
})();

